import React, { Component, useState, useEffect } from "react";
import { InputGroup, Form, Button, Container, Alert } from "react-bootstrap";
import { supabase } from "../auth/supabaseClient";
import Loading from "../Loading";

function Url({ uuid }) {
  const handleChange = (e) => {
    setNewUuid(e.target.value);
  };
  useEffect(() => {
    console.log(newUuid);
  });
  useEffect(() => {
    setNewUuid(uuid);
    setLoading(false);
  }, []);
  const updateUuid = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from("projects")
        .update({ uuid: newUuid })
        .eq("uuid", uuid)
        .select();
      if (error) {
        setMessage({
          message: "That URL is taken, please try another",
          visible: true,
          type: "danger",
        });
        throw error;
      } else {
        setMessage({
          message: "URL has been changed, this page will reload",
          visible: true,
          type: "success",
        });
        let newUrl = window.location.href.split("/admin");
        newUrl[1] = `/admin/project/${newUuid}`;
        newUrl = newUrl.join("");
        setTimeout(() => {
          window.location.href = newUrl;
        }, 1000);
      }
      console.log(data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [newUuid, setNewUuid] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    message: "",
    visible: false,
    type: "danger",
  });
  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <Container>
          {message.visible ? (
            <Alert variant={message.type}>{message.message}</Alert>
          ) : (
            <></>
          )}
          <InputGroup className="mb-3">
            <InputGroup.Text id="url">
              {`${process.env.REACT_APP_URL}sign/`}
            </InputGroup.Text>
            <Form.Control
              id="basic-url"
              aria-describedby="url"
              name="url"
              onChange={handleChange}
              value={newUuid}
            />
          </InputGroup>
          <Button onClick={updateUuid}>Try new URL</Button>
        </Container>
      </>
    );
  }
}

export default Url;
