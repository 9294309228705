import React, { Component, useEffect, useState } from "react";
import YouTubePlayer from "react-youtube";
import Countdown, { CountdownApi } from "react-countdown";

function Youtube({ url }) {
  const [vidLength, setVidLength] = useState(null);

  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {});
  const getVID = (url) => {
    let output = url.split("v=")[1];
    return output;
  };
  useEffect(() => {
    console.log(vidLength);
  });
  const opts = {
    height: null,
    width: null,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };
  const onplay = (e) => {
    console.log("Played");
  };
  const onReady = (e) => {
    setVidLength(e.target.getDuration());
  };

  return (
    <>
      <YouTubePlayer
        videoId={getVID(url)}
        opts={opts}
        onPlay={onplay}
        onReady={onReady}
        iframeClassName="yt-iframe"
      />
    </>
  );
}

export default Youtube;
