import React, { Component, useState, useEffect, useLayoutEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CaptureSignature from "./CaptureSignature";
import GoogleSlides from "./GoogleSlides";
import DisplayInduction from "./DisplayInduction";
import { supabase } from "./auth/supabaseClient";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

function Sign() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const { projectID } = useParams();
  useEffect(() => {
    console.log(formData, dataValid, formValid, project);
  });
  const [message, setMessage] = useState({
    visible: false,
    type: "secondary",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    agree: false,
    signature: null,
    project_id: null,
    induction_id: null,
  });

  const [dataValid, setDataValid] = useState(null);
  const [formValid, setformValid] = useState(false);
  const [formVisible, setformVisible] = useState(true);

  const getProject = async () => {
    setLoading(true);
    try {
      let { data: projects, error } = await supabase
        .from("projects")
        .select(
          `
        *,
        inductions (
          *
        )
      `
        )
        .eq("uuid", projectID);

      if (error) {
        throw error;
      }
      setProject(projects[0]);
      setReqFields(projects[0].data_req);
      setFormData({
        ...formData,
        project_id: projects[0].id,
        induction_id:
          projects[0].inductions[projects[0].inductions.length - 1].id,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const setReqFields = (data_req) => {
    // {
    //   "IP": false,
    //   "Name": true,
    //   "Email": false,
    //   "Phone": false,
    //   "Company": true,
    //   "Location": false,
    //   "Signature": true
    // }
    let output = {};
    let keys = Object.keys(data_req);
    keys.forEach((key) => {
      if (data_req[key]) {
        let lower = key.toLowerCase();
        output[lower] = false;
      }
    });
    setDataValid(output);
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.value) {
      setDataValid({
        ...dataValid,
        [e.target.name]: true,
      });
    } else {
      setDataValid({
        ...dataValid,
        [e.target.name]: false,
      });
    }
    isFormValid(e);
  };
  const isFormValid = (e) => {
    console.log("Form valid ran");
    let allValid = true;
    let keys = Object.keys(dataValid);
    keys.forEach((key) => {
      if (!dataValid[key]) {
        allValid = false;

        if (key == e.target.name && e.target.value) {
          allValid = true;
        }
      }
    });
    if (allValid) {
      setformValid(true);
    } else {
      setformValid(false);
    }
  };
  const handleSignature = (data) => {
    setFormData({ ...formData, signature: data });
    if (data) {
      setDataValid({ ...dataValid, signature: true });
    }
    isFormValid({ target: { name: "signature", value: true } });
  };
  const submitForm = async () => {
    setLoading(true);
    setformVisible(false);
    delete formData.agree;
    try {
      const { data, error } = await supabase
        .from("signatures")
        .insert([formData]);
      if (error) {
        throw error;
      }
      setFormData({
        name: "",
        company: "",
        email: "",
        phone: "",
        agree: false,
        signature: null,
        project_id: null,
        induction_id: null,
      });
      setLoading(false);
      setMessage({
        visible: true,
        type: "success",
        message: project.success_message || t("thanks"),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const setLang = (e) => {
    console.log("Changing");
    i18n.changeLanguage(e.target.value);
  };
  useEffect(() => {
    getProject();
  }, []);
  useEffect(() => {});

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Container className="mt-5 d-flex flex-column align-content-center justify-content-center">
        <Container className="mt-5  align-content-right justify-content-right">
          <Form.Select aria-label="Default select example" onChange={setLang}>
            <option value="null">Set Language</option>
            <option value="en">🇬🇧</option>
            <option value="fr">🇫🇷</option>
          </Form.Select>
        </Container>

        <h2 className="mb-3 text-center">{project.name}</h2>
        {message.visible ? (
          <Alert variant={message.type}>{message.message}</Alert>
        ) : (
          <></>
        )}
        {formVisible ? (
          <>
            {" "}
            <DisplayInduction
              url={project.inductions[project.inductions.length - 1].url}
              urlType={project.inductions[project.inductions.length - 1].type}
            />
            <p className="mb-3">{project.statement}</p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label={t("agree")}
                  name="agree"
                  onChange={handleChange}
                />
              </Form.Group>

              {project.data_req.Name ? (
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>{t("name_label")}</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder={t("name_placeHolder")}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              {project.data_req.Company ? (
                <Form.Group className="mb-3" controlId="company">
                  <Form.Label>{t("company_label")}</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder={t("company_placeHolder")}
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {t("company_helper")}
                  </Form.Text>
                </Form.Group>
              ) : (
                <></>
              )}
              {project.data_req.Phone ? (
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>{t("phone_label")}</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder={t("phone_placeHolder")}
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <Form.Text className="text-muted">
                    {t("phone_helper")}
                  </Form.Text>
                </Form.Group>
              ) : (
                <></>
              )}
              {project.data_req.Email ? (
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>{t("email_label")}</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder={t("email_placeHolder")}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              {project.data_req.Signature ? (
                <CaptureSignature sendData={handleSignature} />
              ) : (
                <></>
              )}

              <Button
                variant="primary"
                onClick={submitForm}
                disabled={!formValid}
              >
                {t("submit")}
              </Button>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}

export default Sign;
