import React, { Component, useEffect, useState } from "react";
import { supabase } from "../auth/supabaseClient";
import {
  Button,
  Table,
  Accordion,
  Container,
  FormControl,
  Form,
} from "react-bootstrap";
import formatDate from "../../libs/timeformat";
import Loading from "../Loading";
import PDF from "./PDF";
import titleCase from "../../libs/titlecase";
import SortButtons from "./SortButtons";

function InductionRegister({ id, project }) {
  const searchDefault = {
    name: "",
    company: "",
  };
  const [signatures, setSignatures] = useState(null);
  const [rawSignatures, setRawSignatures] = useState(null);
  const [search, setSearch] = useState(searchDefault);

  const [fields, setFields] = useState(null);
  const getSignatures = async () => {
    try {
      let { data: signatures, error } = await supabase
        .from("signatures")
        .select("*")
        .eq("project_id", id);
      if (error) {
        throw error;
      }
      setRawSignatures(signatures);
      setSignatures(signatures);
    } catch (error) {
      console.log(error);
    }
  };

  const getCsv = () => {
    console.log(signatures);
    let rows = [["date/time", "name", "company", "sig"]];
    signatures.forEach((person) => {
      let row = [
        person.created_at,
        person.name,
        person.company,
        person.signature,
      ];
      rows.push(row);
    });
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };
  const filterData = (target, field) => {
    let regex = new RegExp(target, "i");
    let filtered = rawSignatures.filter((signature) => {
      if (regex.test(signature[field])) {
        return true;
      }
    });
    setSignatures(filtered);
  };

  const sortFunction = (direction, column) => {
    console.log(
      "this is the sort Function called, ",
      direction,
      " On the column "
    );

    let sorted = [...rawSignatures];

    if (direction == "up") {
      sorted = sorted.sort((a, b) => {
        if (a[column] > b[column]) {
          return -1;
        } else {
          return 1;
        }
      });
    } else if (direction == "down") {
      sorted = sorted.sort((a, b) => {
        if (a[column] > b[column]) {
          return 1;
        } else {
          return -1;
        }
      });
    }
    setSignatures(sorted);
    console.log(signatures);
  };

  const filterByName = (targetName) => {
    let regex = new RegExp(targetName, "i");

    let filtered = rawSignatures.filter((signature) => {
      if (regex.test(signature.name)) {
        return true;
      }
    });
    setSignatures(filtered);
  };
  const getOptionalFields = (data_req) => {
    // {
    //   "IP": false,
    //   "Name": true,
    //   "Email": false,
    //   "Phone": false,
    //   "Company": true,
    //   "Location": false,
    //   "Signature": true
    // }
    let output = [];
    let keys = Object.keys(data_req);
    keys.forEach((key) => {
      if (data_req[key]) {
        let lower = key.toLowerCase();
        output.push(lower);
      }
    });
    setFields(output);
  };

  useEffect(() => {
    getSignatures();
    getOptionalFields(project.data_req);
    console.log(signatures);
  }, []);

  useEffect(() => {
    console.log("signitures", signatures);
    console.log("Rawsignitures", rawSignatures);
    console.log("search", search);

    console.log(fields);
  });

  const handleChange = (e) => {
    console.log(e.target.name);
    setSearch({ ...search, [e.target.name]: e.target.value });
    filterData(e.target.value, e.target.name);
    // if (e.target.name === "name") {
    //   // Run a name search
    //   filterByName(e.target.value);
    // }
  };

  const resetFilters = () => {
    setSignatures([...rawSignatures]);
    setSearch({ ...searchDefault });
  };
  if (!signatures) {
    return <Loading />;
  }

  return (
    <>
      <Accordion className="mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Search / Filter</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Group className="mb-3" controlId="nameSearch">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter a name to search"
                  name="name"
                  value={search.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="companySearch">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter a company to search"
                  name="company"
                  value={search.company}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
            <Button onClick={resetFilters}>Reset</Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Table striped bordered hover className="mb-3" id="induction-reg">
        <thead>
          <tr>
            <th>
              Date / Time
              <SortButtons sortFunction={sortFunction} column={"created_at"} />
            </th>

            {fields.map((field) => {
              return (
                <th>
                  {titleCase(field)}{" "}
                  {<SortButtons sortFunction={sortFunction} column={field} />}
                </th>
              );
            })}
          </tr>
        </thead>
        {signatures.length > 0 ? (
          <tbody>
            {signatures.map((signature) => {
              return (
                <tr key={signature.id}>
                  <td>{formatDate(signature.created_at)}</td>
                  {fields.map((field) => {
                    if (field === "signature") {
                      if (!signature.signature) {
                        return <td></td>;
                      }
                      return (
                        <td>
                          <img
                            className="induction-reg-img"
                            src={signature.signature}
                          />
                        </td>
                      );
                    } else {
                      return <td>{signature[field]}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <>No Results</>
        )}
      </Table>
      <h5>Export Register</h5>
      <Button className="mr-2" onClick={getCsv}>
        CSV
      </Button>{" "}
      <PDF
        className="ml-2"
        data={signatures}
        headers={[["Date / Time", "Name", "Company", "Signature"]]}
        project={project}
      />
    </>
  );
}

export default InductionRegister;
