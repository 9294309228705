function titleCase(sentence) {
  // Convert it to lower case
  sentence = sentence.toLowerCase();
  // Split on space
  // For each word take the first char
  let arr = sentence.split("");
  // Make Upper Case
  arr[0] = arr[0].toUpperCase();
  let output = arr.join("");
  // Return it
  return output;
}

export default titleCase;
