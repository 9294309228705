import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    resources: {
      en: {
        translation: {
          agree: "I agree",
          name_label: "Your Name",
          name_placeHolder: "Enter Your Name",
          company_label: "Your Company",
          company_placeHolder: "Enter Your Company",
          company_helper:
            "Please enter the name of the company you are working for onsite, if you are freelance, please tell us which department you are working for.",
          email_label: "Your Email",
          email_placeHolder: "Enter your email address",
          phone_label: "Your Phone Number",
          phone_placeHolder: "Enter your Phone Number",
          phone_helper:
            "Please enter your phone number so we can contact you while onsite",
          sign_label: "Please sign below",
          sign_reset: "Reset",
          sign_save: "Save",
          submit: "Submit",
          thanks: "Thanks, you have signed the register",
        },
      },
      fr: {
        translation: {
          agree: "Je suis d'accord",
          name_label: "Votre nom",
          name_placeHolder: "Entrez votre nom",
          company_label: "Votre Entreprise",
          company_placeHolder: "Entrez le nom de votre entreprise",
          company_helper:
            "Veuillez saisir le nom de l'entreprise pour laquelle vous travaillez sur site. Si vous êtes indépendant, veuillez nous indiquer pour quel département vous travaillez.",
          email_label: "Votre email",
          email_placeHolder: "Entrez votre email",
          phone_label: "Votre numéro de téléphone",
          phone_placeHolder: "Entrez votre numéro de téléphone",
          phone_helper:
            "Veuillez entrer votre numéro de téléphone pour que nous puissions vous contacter sur site",
          sign_label: "Merci de bien vouloir signer ci-dessous",
          sign_reset: "Réinitialiser",
          sign_save: "Enregistrer",
          submit: "Soumettre",
          thanks: "Merci, vous avez bien signé le registre.",
        },
      },
    },
  });

export default i18n;
