import React, { Component } from "react";
import { Button, Container, Row } from "react-bootstrap";
import logo from "../img/inductmelive-high-resolution-logo-black-on-transparent-background.png";
import { redirect } from "react-router-dom";

function Home({ session }) {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center text-center">
      <img src={logo} className="home-logo " />
      {session ? (
        <p>
          <a href="./admin/new">Start a new project</a>
        </p>
      ) : (
        <h5 className="mt-5  font-italic">
          inductme.live is a product of{" "}
          <a href="https://eventsafetyplan.com">Event Safety Plan</a>.
        </h5>
      )}
    </Container>
  );
}

export default Home;
